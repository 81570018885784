<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-dialog
            v-model="dialogBuscar"
            :width="$vuetify.breakpoint.smAndUp ? '60%' : ''"
         >
            <v-card>
               <v-card-title>Buscar por nombre</v-card-title>
               <v-card-text>
                  <v-container class="pt-0 mt-0">
                     <v-layout wrap>
                        <v-flex xs12 class="d-flex justify-center">
                           <div
                              :style="
                                 $vuetify.breakpoint.smAndUp ? 'width: 60%' : ''
                              "
                           >
                              <v-text-field
                                 v-model="searchComputed"
                                 label="Busqueda"
                                 color="primary"
                                 prepend-icon="fas fa-search"
                              >
                              </v-text-field>
                           </div>
                        </v-flex>
                        <v-flex xs12>
                           <v-data-table
                              :headers="headers"
                              :items="resContratos"
                              :items-per-page="6"
                              no-data-text="No hay contratos."
                              no-results-text="Sin resultados..."
                              :loading="loadingContratos"
                              class="elevation-1"
                              @dblclick:row="
                                 (event, row) => asignarCarnet(row.item)
                              "
                           ></v-data-table>
                        </v-flex>
                        <v-flex xs12> </v-flex>
                     </v-layout>
                  </v-container>
               </v-card-text>
            </v-card>
         </v-dialog>
         <v-card elevation="0">
            <v-card-text class="text--primary" style="margin-top: -20px">
               <v-container grid-list-md fluid>
                  <v-layout wrap>
                     <v-flex xs6>
                        <span class="text-h5 font-weight-medium">
                           Ver pantallas de alumno
                        </span>
                     </v-flex>
                     <v-flex xs12>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Ingrese un número de carnet
                        </span>
                     </v-flex>

                     <v-flex xs12 sm3>
                        <v-text-field
                           v-model="carnet"
                           v-mask="'####-AA-###'"
                           label="Carnet"
                           color="primary"
                           @keypress.enter="generarEnlaces"
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm3 mt-3>
                        <v-btn color="primary" block @click="generarEnlaces"
                           >Generar enlaces</v-btn
                        >
                     </v-flex>
                     <v-flex xs12 sm3 mt-3>
                        <v-btn
                           block
                           color="primary"
                           outlined
                           @click="dialogBuscar = true"
                           >Buscar por nombre</v-btn
                        >
                     </v-flex>
                  </v-layout>
                  <v-layout wrap>
                     <v-flex xs12 mt-2>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Enlaces generados:
                           <span class="colorPrimary">{{ nombre }}</span>
                        </span>
                     </v-flex>
                     <v-flex xs12 sm3 mt-3>
                        <v-btn
                           width="100%"
                           :disabled="!datosObtenidos"
                           color="primary"
                           @click="irReporteTalonario"
                        >
                           Reporte talonario
                        </v-btn>
                     </v-flex>
                     <v-flex xs12 sm3 mt-3>
                        <v-btn
                           width="100%"
                           :disabled="!datosObtenidos"
                           outlined
                           color="primary"
                           @click="irColecturiaDigital"
                        >
                           Colecturía digital
                        </v-btn>
                     </v-flex>
                  </v-layout>
                  <v-divider class="my-9"></v-divider>
                  <v-layout wrap>
                     <v-flex xs6>
                        <span class="text-h5 font-weight-medium">
                           Generar talonario en ciclo pasado para proceso de
                           plan de pago
                        </span>
                     </v-flex>
                     <v-flex xs12>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Ingrese un número de carnet
                        </span>
                     </v-flex>

                     <v-flex xs12 sm3>
                        <v-text-field
                           v-model="carnetPlanPago"
                           v-mask="'####-AA-###'"
                           label="Carnet"
                           color="primary"
                        ></v-text-field>
                     </v-flex>
                     <v-flex shrink mt-3>
                        <v-btn
                           color="primary"
                           :disabled="showLoadingPlanPago"
                           @click="generarTalonario"
                           >Generar talonario</v-btn
                        >
                     </v-flex>
                     <v-flex v-if="showLoadingPlanPago" shrink mt-2 ml-2>
                        <i
                           class="fas fa-sync-alt fa-3x fa-spin colorPrimary"
                           data-fa-transform="down-5  right-5"
                        ></i>
                     </v-flex>
                  </v-layout>
                  <v-layout wrap>
                     <v-flex xs12 mt-2>
                        <span
                           class="font-weight-medium"
                           style="font-size: 20px"
                        >
                           Talonario generado:
                           <span class="colorPrimary">{{
                              codigoTalonarioPlanPago
                           }}</span>
                        </span>
                     </v-flex>
                  </v-layout>
               </v-container>
            </v-card-text>
         </v-card>
         <v-container grid-list-md fluid>
            <v-layout wrap> </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'
import debounce from 'lodash/debounce'

export default {
   name: 'VistaAlumno',
   directives: {
      mask
   },
   data: () => ({
      // Variables
      carnet: '',
      carnetPlanPago: '',
      showLoadingPlanPago: false,
      talonarioPlanPago: null,
      codigoTalonarioPlanPago: '',
      periodoLectivo: '',
      accessCode: '',
      nombre: '',
      linkTalonario: '',
      linkColecturiaDigital: '',
      datosObtenidos: false,
      dialogBuscar: false,
      search: '',
      loadingContratos: false,
      headers: [
         {
            text: 'Carnet',
            value: 'cliente'
         },
         {
            text: 'Periodo',
            value: 'periodoLectivo'
         },
         {
            text: 'Estado',
            value: 'estado'
         },
         {
            text: 'Nombre',
            value: 'nombre'
         }
      ],
      resContratos: []
   }),
   computed: {
      ...authComputed,
      searchComputed: {
         get() {
            return this.search
         },
         set: debounce(function (newVal) {
            this.search = newVal.trim()
         }, 500)
      }
   },
   watch: {
      search() {
         this.buscarPorNombre()
      }
   },
   created() {
      // Validando acceso al componente
      if (this.permController(3, 'ver')) {
         // Do nothing
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      asignarCarnet(item) {
         this.carnet = item.cliente
         this.dialogBuscar = false
         setTimeout(() => {
            this.generarEnlaces()
         }, 500)
      },
      buscarPorNombre() {
         this.loadingContratos = true
         axios
            .get(`api/Contrato/ByNombre?nombre=${this.search}`)
            .then((response) => {
               this.resContratos = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.loadingContratos = false
            })
      },
      generarTalonario() {
         this.talonarioPlanPago = null
         this.codigoTalonarioPlanPago = ''
         this.showLoadingPlanPago = true
         axios
            .post('api/Contrato/generarTalonario?carnet=' + this.carnetPlanPago)
            .then((response) => {
               this.talonarioPlanPago = response.data
               this.codigoTalonarioPlanPago =
                  this.talonarioPlanPago.codigoTalonario
               this.showLoadingPlanPago = false
            })
            .catch(() => {
               this.$store.dispatch('showSnack', {
                  message: 'No se pudo generar el talonario',
                  color: 'snackError'
               })
               this.showLoadingPlanPago = false
            })
      },
      generarEnlaces() {
         this.nombre = ''
         this.accessCode = ''
         this.linkTalonario = ''
         this.linkColecturiaDigital = ''
         this.datosObtenidos = false
         if (this.carnet.trim() != '') {
            axios
               .get('api/Alumno/Credenciales?carnet=' + this.carnet)
               .then((response) => {
                  if (response.data[0] !== undefined) {
                     var res = response.data[0]
                     this.accessCode = res.accessCode
                     this.nombre = res.nombre
                     this.datosObtenidos = true
                  }
               })
               .catch((error) => {
                  console.log(error)
               })
         }
      },
      irReporteTalonario() {
         window.open(
            process.env.VUE_APP_CLIENTE +
               'reporteTalonario?carnet=' +
               this.carnet +
               '&ciclo=2&anio=2020&accesscode=' +
               this.accessCode,
            '_blank'
         )
      },
      irColecturiaDigital() {
         window.open(
            process.env.VUE_APP_CLIENTE +
               'colecturiaDigital?carnet=' +
               this.carnet +
               '&accesscode=' +
               this.accessCode,
            '_blank'
         )
      }
   }
}
</script>
